/* .login {
  margin-top: 60px;
  margin-bottom: 60px;
} */
.log_gap img {
  height: 500px;
}
.form {
  display: flex;
  justify-content: center;
  /* background: #ECF0F1;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2); */
}

.back {
  background: #ecf0f1;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
}

.log_link {
  text-align: center;
}

.log_link a {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 5px 20px;
  margin: 10px;
  color: #000;
}

.log_text h2 {
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
}

/* .form {
  padding-top: 80px;
  padding-bottom: 80px;
} */

.link_active {
  background: #3498db;
  color: #fff !important;
}

.form_login {
  width: 300px;
  margin: 40px auto;
}

.user,
.pass {
  border: 1px solid #fff;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 20px;
}

.user input,
.pass input {
  border: none;
  background-color: transparent;
  width: 100%;
  padding: 5px 20px;
}

.user input:focus,
.pass input:focus {
  outline: none;
  background-color: transparent;
}

.control_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remember label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(44, 62, 80, 0.8);
  margin-left: 10px;
}

.control_area a {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: rgba(44, 62, 80, 0.8);
}

.form_submit button {
  width: 100%;
  background: #3498db;
  border-radius: 10px;
  border: none;
  color: #ecf0f1;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
}

.dont_account {
  text-align: center;
}

.dont_account a {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgba(44, 62, 80, 0.8);
  margin-top: 10px;
  display: block;
}
.dont_account span {
  color: #3498db;
}
.forgetPass a {
  color: #3498db;
}

/* ============== */
@media only screen and (max-width: 768px) {
  .login {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
