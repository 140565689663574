.privicy_banner {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) -35.54%,
    rgba(147, 184, 193, 0.4) 134.11%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 70px;
}

.privicy_banner h2 {
  font-weight: 700;
  font-size: 70px;
  line-height: 95px;
  text-align: center;
}

.privicy_banner p {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #2c3e50;
}

.privicy_info {
  width: 70%;
  margin: 50px auto;
  margin-bottom: 50px;
}

.privicy_info p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: justify;
  color: #2c3e50;
  margin-bottom: 20px;
}

.privicy_info h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  text-align: justify;
  color: #2c3e50;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .privicy_banner {
    margin-top: 44px;
    text-align: center;
  }
}
