.resources_area {
  background-color: #ecf0f1;
  padding-top: 60px;
  padding-bottom: 60px;
}

.resourse_content {
  margin-right: 40px;
}

.resourse_content small {
  font-weight: 400;
  font-size: 14px;
  line-height: 0px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  display: block;
  margin-bottom: 15px;
}

.resourse_content h2 {
  margin-top: 20px;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: #2c3e50;
  margin-bottom: 20px;
}

.resourse_content p {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
  color: #2c3e50;
  display: block;
  margin-bottom: 30px;
}

.know_more a {
  text-decoration: none;
  background: #3498db;
  border: 2px solid #00b2e2;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 12px 40px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
}
.know_more a:hover {
  background: #fff;
  border: 2px solid #00b2e2;
  color: #2c3e50;
}

.service_picture {
  display: flex;
  align-items: center;
  position: relative;
}
.service_picture:hover {
  cursor: pointer;
}

.service_picture img {
  width: 100%;
  height: 400px;
}
.play {
  font-size: 60px;
  color: #00b2e2;
}
.service_picture:hover .play {
  animation: heartbeat 2s infinite;
  cursor: pointer;
}
.service_play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

/* ===============Responsive================ */

@media only screen and (max-width: 768px) {
  .resourse_content {
    margin-right: 0;
  }
  .resourse_content h2 {
    font-size: 25px;
    line-height: 25px;
    text-align: center;
  }
  .service_picture img {
    height: auto;
  }
  .play {
    font-size: 40px;
  }
  .know_more {
    margin-bottom: 40px;
  }
  .resources_area {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
