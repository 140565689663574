.hire_us {
  background-color: #ecf0f1;
  padding-top: 60px;
  padding-bottom: 60px;
}

.hire_text {
  text-align: center;
}

.hire_text p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: 5px;
}

.hire_text h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: #2c3e50;
  margin-bottom: -20px;
}

.blog_item img {
  width: 100%;
  border: 1x solid #ecf0f1;
  border-radius: 10px;
}

.blog_item {
  position: relative;
  margin-top: 70px;
  margin-right: 30px;
}

.learn_more {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(41, 128, 185, 0.8);
  z-index: 222;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 10px;
  transition: 0.3s all ease-in;
}

.learn_more:hover {
  opacity: 1;
}

.learn_more button {
  background-color: #ffffff;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2980b9;
  padding: 12px 40px;
  display: block;
}

/* ===============Responsive================ */

@media only screen and (max-width: 768px) {
  .hire_us {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .hire_text h2 {
    font-size: 24px;
    line-height: 24px;
  }
  .blog_item {
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: -10px;
  }
  .blog_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8%;
  }
}
