.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordion-item .accordion-button:hover {
  background-color: #3498db;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.accordion-item:first-of-type {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.accordion-item {
  background: #ecf0f1;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 5px;
}

.accordion-button {
  background-color: #ecf0f1;
  border-radius: 10px;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #fff !important;
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background: #3498db;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.faq_content {
  margin-left: 20px;
}

.faq_content p {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: justify;
  color: #2c3e50;
}

.faq_button a {
  background: #3498db;
  border: 2px solid #3498db;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  display: inline-block;
  border-radius: 10px;
  padding: 13px 41px;
  margin-top: 15px;
  color: #fff;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
}

.faq_button a:hover {
  background: #fff;
  border: 2px solid #3498db;
  color: #2c3e50;
}
.provide_top {
  text-align: center;
  margin-top: 80px;
}

.provide_top p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: 0px;
}

.provide_top h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #2c3e50;
}

.provide_tabs {
  margin-top: 60px;
}

.provide_tabs Tab {
  display: flex;
  justify-content: center;
}

.nav-tabs {
  display: flex;
  justify-content: space-around;
  border-bottom: none;
}

.nav-tabs .nav-link {
  margin-bottom: none;
  background: #ffffff;
  border: 2px solid #3498db !important;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #3498db;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.provide_content {
  width: 100%;
  margin: 0 auto;
  margin-top: 65px;
}

.banner img {
  width: 100%;
  height: 380px;
}

.banner_content {
  margin-left: 30px;
}

.banner_content p,
ul {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: justify;
  color: #2c3e50;
}

.banner_content ul {
  font-weight: 400;
}

.button_area {
  display: flex;
}

.banner_button button {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 12px 40px;
  color: #fff;
  margin-top: 24px;
}

.get_stered button {
  background: #3498db;
  margin-right: 20px;
}

.contuct_us button {
  background: #e74c3c;
}

/* ====================Responsive================== */

@media only screen and (max-width: 768px) {
  .provide_top {
    margin-top: 30px;
    margin-bottom: -25px;
  }
  .provide_top p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: -10px;
  }
  .provide_top h2 {
    font-size: 24px;
    line-height: 20px;
    margin-top: 20px;
  }
  .provide_tabs {
    display: block;
    text-align: center;
    width: 100%;
  }
  .nav-tabs {
    margin-bottom: 10px;
    display: grid;
    grid: 80px 80px / auto auto;
    justify-content: center;
    grid-gap: 10px;
  }
  .nav-tabs .nav-link {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 15px;
    padding: 5px 15px;
  }
  .banner img {
    height: auto;
    margin-bottom: 30px;
  }
  .banner_content p,
  ul {
    font-size: 14px;
  }

  .provide_conten ul li {
    margin-left: -5px;
  }
  .banner_content {
    margin: 10px;
  }
  .button_area {
    display: flex;
    justify-content: center;
  }
  .faq_content p {
    margin-top: 15px;
    margin-left: -10px;
    margin-right: 18px;
    font-weight: 500;
  }
  .accordion_gap {
    margin-top: -30px;
  }
}
