.search_hero {
  background-image: url(../../../Images/searchHero.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60vh;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_hero::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #2c3e5066;
  opacity: 0.7;
  z-index: -11;
  top: 0;
  left: 0;
}

.search_hero h2 {
  text-align: center;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 95px;
  color: #fff;
}

/* =======================Responsive============= */
@media only screen and (max-width: 768px) {
  .search_hero {
    margin-top: -5px;
    height: 30vh;
  }
  .search_hero h2 {
    font-size: 30px;
    line-height: 40px;
  }
}
