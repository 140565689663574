.galarry_text p {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: 5px;
}

.galarry_text h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: #2c3e50;
}

.galary_btn {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 40px;
}

.galary_btn button {
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: #3498db;
  border: none;
  background: transparent;
  margin-right: 30px;
  transition: 0.5s all ease;
  border-top: 2px solid transparent;
  padding-top: 10px;
  margin-bottom: 30px;
}

.galary_btn button:hover {
  border-top: 2px solid #3498db;
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
}

.overLine hr {
  position: relative;
  height: 2px;
  top: 28px;
}

.glarry_img {
  position: relative;
  margin-top: 10px;
  margin-left: 0;
  margin-right: 5px;
  margin-bottom: 10px;
}

.glarry_img img {
  width: 100%;
}

.project_details {
  background: rgba(52, 152, 219, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  transition: 0.5s all ease;
}

.project_details:hover {
  opacity: 1;
  cursor: pointer;
}

.project_info {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  width: 250px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40%;
  text-align: center;
}

.project_info h3 {
  font-weight: 600;
  font-size: 16px;
  color: #2980b9;
  margin-bottom: 0;
}

.project_info p {
  font-weight: 400;
  font-size: 14px;
  color: #2c3e50;
  margin-bottom: 0;
}

.project_info small {
  font-weight: 600;
  font-size: 12px;
  color: #2c3e50;
}

.loadMore {
  text-align: center;
  margin: 20px;
}

.loadMore button {
  background: #3498db;
  border: 2px solid #3498db;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #fff;
  padding: 12px 40px;
  transition: 0.2s all ease;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  -ms-transition: 0.2s all ease;
  -o-transition: 0.2s all ease;
}

.loadMore button:hover {
  background: #fff;
  border: 2px solid #3498db;
  color: #2c3e50;
}

/* ==================Responsive=============== */

@media only screen and (max-width: 768px) {
  .galary_btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 0;
  }

  .galary_btn button {
    margin-top: 0px;
    margin-bottom: -20px;
  }

  .galarry_text h2 {
    font-size: 30px;
    line-height: 30px;
  }

  .overLine {
    margin-top: -30px;
  }

  .galarry_text p {
    line-height: 20px;
  }

  .galary_btn {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .glarry_img {
    margin-top: 20px;
  }

  .galary_btn button:hover {
    border-top: none;
  }
}