.banner_area {
  background-image: url(../../Images/service_banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 50vh;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner_area::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #2c3e5066;
  opacity: 0.7;
  z-index: -11;
  top: 0;
  left: 0;
}

.banner_area h2 {
  text-align: center;
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 95px;
  color: #fff;
}

.search_area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search {
  display: flex;
  justify-content: space-between;
  border: 1px solid #fff;
  border-radius: 20px;
  background-color: #fff;
}

.search input {
  border: none;
  background-color: transparent;
  width: 540px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
  margin-right: 30px;
  font-size: 18px;
}

.search input:focus {
  outline: none;
}

.submite {
  display: flex;
  align-items: center;
}

.submite button {
  background-color: transparent;
  border: none;
  margin-right: 20px;
}

/* =========================Responsive========================= */

@media only screen and (max-width: 768px) {
  .banner_area {
    margin-top: -5px;
    margin-top: 44px;
    height: 20vh;
  }
  .banner_area h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .search {
    width: 300px;
  }
  search input {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .resourse_content small {
    text-align: center;
  }
  .resourse_content h2 {
    font-size: 18px;
    text-align: center;
  }
  .know_more {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
  }
  .service_picture img {
    height: 100%;
  }
  .play {
    font-size: 40px;
  }
  .search input {
    width: 250px;
  }
}
