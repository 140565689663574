.visitor_info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.namef,
.emailf {
  width: 48%;
}

.emailf input,
.namef input {
  width: 100%;
  background: #ffffff;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
}

.emailf input:focus,
.namef input:focus,
.subject input:focus,
.meassage textarea:focus {
  outline: none;
}

.subject {
  width: 100%;
  margin-top: 25px;
}

.subject input {
  width: 100%;
  background: #ffffff;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
}

.meassage {
  width: 100%;
}

.meassage textarea {
  width: 100%;
  height: 200px;
  resize: none;
  padding: 20px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 25px;
  border: 1px solid #fff;
}

.submit_btn {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.submit_btn button {
  background: #ecf0f1;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: none;
  padding: 8px 35px;
  color: #2c3e50;
}
.submit_btn button:hover {
  background: #e74c3c;
  color: #fff;
}
