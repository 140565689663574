.pricing_plan {
  padding-top: 80px;
  background-color: #ecf0f1;
}

.pricing_text p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #2c3e50;
  text-transform: uppercase;
}

.pricing_text h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #2c3e50;
}

.plan {
  margin-top: 80px;
  margin-bottom: 80px;
}

.plan_card {
  background: #ffffff;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
}

.plan_card p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #2c3e50;
  padding-top: 20px;
  padding-bottom: 20px;
}

.plan_icon {
  display: flex;
  justify-content: center;
}

.plan_icon img {
  width: 100px;
  height: 100px;
}

.plan_card h2 {
  font-weight: 600;
  font-size: 50px;
  line-height: 76px;
  text-align: center;
  color: #000000;
}

.plan_card small {
  font-size: 14px;
}

.offer_item {
  text-align: center;
}

.offer_item li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2c3e50;
}

.get_plan {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 40px;
  display: block;
}

.get_plan a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #3498db;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 12px 40px;
  display: inline-block;
}

.get_middle a {
  background: #ecf0f1;
  border-radius: 10px;
  color: #000;
}

.plan_mid {
  transform: scaleY(1.1);
  background: #2f8bc9;
  color: #fff !important;
}

.plan_mid h2,
.offer_mid li {
  color: #fff;
}

.mid_plan_text p {
  color: #fff;
}

/* =========Resposive================ */
@media only screen and (max-width: 768px) {
  .pricing_pla {
    top: 40px;
  }
  .pricing_text h2 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
  }
  .plan_card {
    margin-bottom: 30px;
  }
  .offer_item li {
    list-style: none;
  }
}
