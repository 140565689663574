.teamMember_area {
  padding-top: 50px;
  padding-bottom: 50px;
}

.teamMember_top p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: -5px;
}
.teamMember_head h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 40px;
}
.team_card {
  width: 360px;
  position: relative;
  transition: all 0.5s ease;
  cursor: pointer;
}

.team_img img {
  width: 360px;
  border-radius: 10px;
}
.team_info {
  width: 280px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #fff;
  position: absolute;
  transform: translate(-50%);
  left: 50%;
  bottom: -10%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.team_info h2 {
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  color: #2c3e50;
  margin-bottom: -10px;
}
.team_info p {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 15px;
}
.team_social {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: -10px;
  display: none;
}
.team_social_icon a {
  font-size: 20px;
  color: #e74c3c;
  margin: 10px;
  margin-top: -20px;
}

.team_card:hover .team_social {
  display: flex;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
}
.teamMember_area .swiper {
  width: 100%;
  height: 440px !important;
  padding-right: 5px;
}

@media only screen and (max-width: 768px) {
  .teamMember_head h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
  }
  .teamMember_area {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 1400px) {
  .team_slider {
    margin-left: 55px;
  }
}
