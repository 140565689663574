.career_banner {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) -35.54%,
    rgba(147, 184, 193, 0.4) 134.11%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 70px;
}

.career_text h2 {
  font-weight: 700;
  font-size: 70px;
  line-height: 95px;
  text-align: center;
}

/* ===============Responsive================ */

@media only screen and (max-width: 768px) {
  .career_banner {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 24px;
    margin-top: 44px;
  }
  .apply_text h2 {
    font-size: 20px;
    line-height: 20px;
  }
  .job_expertis {
    text-align: center;
  }
  .job_name h2 {
    text-align: center;
  }
  .selary {
    text-align: center;
  }
  .job_apply {
    justify-content: center;
  }
  .career_text h2 {
    font-size: 50px;
    line-height: 50px;
  }
  .apply_text p {
    margin-bottom: 0;
  }
}
