.log_back {
  width: 100%;
  position: relative;
}

.logimg img {
  width: 100%;
  height: 630px;
  margin-left: 15px;
}

.logSocial {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logSocial ul {
  display: flex;
}

.logSocial ul li {
  list-style: none;
  text-align: center;
  margin: 10px;
  font-size: 20px;
}
.logSocial ul li a {
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .logimg img {
    margin-left: 0;
    padding: 0px;
  }
  .log_back {
    display: none;
  }
}
