.navBg {
  padding-top: 0;
  padding-bottom: 0;
  background: #3498db;
}

.arrow {
  margin-left: 3px;
}

.nav-link a {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  margin-right: 15px;
  text-decoration: none;
  margin-bottom: 10px;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 14px;
  padding-top: 14px;
  border-bottom: 2px solid transparent;
}

.navbar-light .navbar-nav .nav-link:hover {
  border-bottom: 2px solid #ffff;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
  color: #ffff !important;
  border-bottom: 2px solid #ffff;

}



.dropdown-menu[data-bs-popper] {

  margin-top: 0 !important;
}

.dropdown-toggle::after {
  vertical-align: 1px !important;
}

.dropdown-item {
  font-size: 16px !important;
  font-weight: 500 !important;
}



/* .nav-link a:hover {
  border-bottom: 2px solid #fff;
} */

.navbar-light .navbar-toggler {
  color: #fff !important;
  border: 1px solid;
  border-color: #fff !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-toggler:focus {
  text-decoration: none;
}

.sing_area {
  width: 230px !important;
  background-color: #e74c3c;
  justify-content: center;
  height: 60px;
  display: flex;
  align-items: center;
  border-left: 5px solid #fff;
  border-right: 5px solid #fff;
  padding-top: 20px;
  padding-right: 0px;
}

.sing_area span {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  display: block;
}

.navBrand {
  display: none !important;
  width: 200px;
}

.navBrand img {
  width: 100%;
}

/* .navbar-light .navbar-nav .nav-link:hover a,
.navActive {
  border-bottom: 2px solid #fff;
  padding-bottom: 15px;
  padding-top: 15px;
} */

/* =====================Responsive=========== */

@media only screen and (max-width: 768px) {

  .navbar-light .navbar-nav .nav-link:hover a,
  .navActive {
    padding: 0;
    /* margin: 0; */
  }

  .navbar-light .navbar-nav .nav-link {
    margin-left: 20px !important;
  }

  .sing_area {
    width: 100% !important;
  }

  .navbar>.container {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  .navBrand {
    display: inline !important;
  }

  .navbar>.container {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between !important;
  }

  .navBg {
    background-color: #fff;
    margin-bottom: 5px !important;
    border-bottom: 2px solid #3498db;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
  }

  .nav-link a {
    color: #2c3e50;
    display: block;
  }


  .nav-link a.navActive {
    color: #3498db;
    padding: 0;
  }

  .sing_area {
    margin-bottom: 10px;
  }

  .navbar-light .navbar-toggler-icon {
    border: 2px solid #3498db;
    padding: 10px !important;
    border-radius: 6px;
    display: inline-flex;
  }

  .navbar-light .navbar-nav .nav-link:hover a,
  .navActive {
    /* padding-bottom: 14px;
    padding-top: 15px; */
    color: #3498db;
    border: none !important;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #2c3e50 !important;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 14px;
    padding-top: 14px;
    border-bottom: 2px solid transparent;
  }

  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .show>.nav-link {
    color: #2c3e50 !important;
    border-bottom: 2px solid #ffff;
  }

  .dropdown-item {
    text-align: center;
    color: #fff;
  }

  .dropdown-menu {
    background-color: #3498db;
  }
}