.project_Start {
  margin-top: 80px;
  margin-bottom: 80px;
}

.lests_start_text h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #2c3e50;
  margin-bottom: 20px;
}

.lests_start_text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: justify;
  color: #2c3e50;
}

.contuct_now_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.quality {
  padding: 30px;
  margin-top: 70px;
}

.quality:hover {
  background: #ffffff;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
}

.quality h2 {
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  color: #000000;
}

.quality span {
  color: #e74c3c;
  margin-right: 10px;
}

.quality p {
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #2c3e50;
  font-weight: 600;
  margin-top: 16px;
}
@media only screen and (max-width: 768px) {
  .contuct_now_btn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .lests_start_text h2 {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }
  .quality {
    padding: 20px;
    margin-top: 20px;
  }
  .quality span {
    display: block;
    text-align: center;
  }
  .quality h2 {
    font-size: 18px;
    text-align: center;
  }

  .quality p {
    font-size: 16px;
    line-height: 20px;
  }
}
