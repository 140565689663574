.about_hero {
  background-image: url(../../../Images/about_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  z-index: 11;
}

.about_hero::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(44, 62, 80, 0.4);
  z-index: -111;
}

.about_text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_text h2 {
  font-size: 70px;
  line-height: 95px;
  text-align: center;
  color: #ffffff;
}

/* ===============Responsive================ */

@media only screen and (max-width: 768px) {
  .about_hero {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 44px;
  }
  .about_text h2 {
    font-size: 30px;
  }
  .about_hero {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
