.buisness_area {
  background-color: #ecf0f1;
  margin-top: 80px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.buisness_content {
  text-align: center;
}

.buisness_content p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-bottom: -5px;
}

.buisness_content h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;
}

.content_sl {
  position: relative;
}

.content_sl::after {
  position: absolute;
  content: "";
  top: 50%;
  width: 94%;
  height: 2px;
  background-color: #000;
}

.number {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 2px solid #000;
  text-align: center;
  border-radius: 50%;
}

.number:hover {
  background-color: #00b2e2;
  color: #fff;
  border: none;
}

.number h1 {
  margin-top: 4px;
  font-size: 24px;
}

.discussion {
  margin-top: 40px;
}

.discussion h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #3498db;
}

.discussion p {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2c3e50;
  margin-left: 20px;
}

/* ===============Responsive================ */

@media only screen and (max-width: 768px) {
  .content_sl::after {
    display: none;
  }
  .content_sl {
    text-align: center;
    margin-top: 30px;
  }
  .last_content {
    text-align: center;
  }
  .buisness_content h2 {
    font-size: 20px;
    line-height: 40px;
  }
  .content_gap {
    margin-top: -15px;
  }

  .discussion h3 {
    text-align: center;
  }
  .discussion p {
    text-align: center;
    margin-left: -4px;
    padding: 4px;
  }
  .buisness_area {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
  }
  .buisness_content p {
    line-height: 16px;
  }
  .discussion {
    margin-top: 10px;
  }
}
