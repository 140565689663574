.contuct_hero {
  background-image: url(../../../Images/contuctBg.jpg);
  padding-top: 200px;
  padding-bottom: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 11;
}

.contuct_hero::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(44, 62, 80, 0.4);
  z-index: -11;
}

.contuct_text h1 {
  font-weight: 700;
  font-size: 70px;
  line-height: 95px;
  text-align: center;
  color: #ffffff;
}
/* =================Responsive================ */

@media only screen and (max-width: 768px) {
  .contuct_hero {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 44px;
  }
  .contuct_text h1 {
    font-size: 30px;
    line-height: 30px;
  }
}
