footer {
  padding-top: 40px;
}

.top_footer {
  background-image: url(../../Images/fback.png);
}

.logo {
  width: 100%;
}

.logo img {
  width: 100%;
}

.footer_text p {
  font-weight: 400;
  font-size: 15px;
  color: #2c3e50;
  text-align: justify;
  margin-top: 30px;
}

footer .media_social {
  margin-left: -35px;
}

.footer_contuct {
  margin-left: 30px;
}

.footer_contuct h3,
.quicLink h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #2980b9;
  margin-bottom: 20px;
}

.footer_contuct p {
  font-weight: 400;
  font-size: 15px;

  color: #2c3e50;
}

.footer_contuct p span {
  font-weight: 700;
}

.quicLink h3 {
  margin-left: 30px;
}

.quicLink ul li {
  list-style: none;
}

.quicLink ul li a {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2c3e50;
  text-decoration: none;
}

.quicLink ul li a:hover {
  color: #2980b9;
}

.email_form {
  width: 100%;
  border-bottom: 1px solid #2c3e50;
  margin-top: 10px;
  margin-bottom: 24px;
}

.email_form input {
  width: 100%;
  border: none;
  background-color: transparent;
  padding-right: 20px;
}

.email_form input:focus {
  outline: none;
}

.subsCribe button {
  background: #3498db;
  border: 2px solid #3498db;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #fff;
  padding: 8px 30px;
  transition: 0.2s all ease;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  -ms-transition: 0.2s all ease;
  -o-transition: 0.2s all ease;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.subsCribe button:hover {
  background: #fff;
  color: #3498db;
  border: 2px solid #3498db;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.subsCribe p {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #2c3e50;
  margin-top: 34px;
}

.googlePlay,
.apple_store {

  margin-right: 10px;
}

.googlePlay a img {
  width: 120px;
}

.apple_store a img {
  width: 120px;
}

.paly_store {
  display: flex;
}

/* bottomFooter */

.footerVBottom {
  height: 50px;
  background-color: #2c3e50;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.footerVBottom p {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 50px;
  color: #ffffff;
  display: block;
  margin-top: 15px;
}

.copy {
  font-size: 20px;
  margin-right: 5px;
}

.copy span {
  color: #3498db;
}

.logo_footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo_footer img {
  width: 100%;
}

.footerVBottom span a {
  color: #3498db;
  font-weight: 700;
  margin-left: 5px;
  margin-right: 5px;
}

/* ========================Responsive=================== */

@media only screen and (max-width: 768px) {
  .footer_contuct {
    margin-left: 0;
    text-align: center;
  }

  .quicLink,
  .quicLink ul li {
    text-align: center;
    margin-left: 0;
  }

  .logo_footer {
    display: flex;
    justify-content: center;
    width: 250px;
  }

  .fImage {
    display: flex;
    justify-content: center;
  }

  .footer_contuct p {
    font-size: 16px;
    line-height: 16px;
    margin-top: -10px;
  }

  .subsCribe {
    text-align: center;
  }

  .quicLink h3 {
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .subsCribe p {
    font-size: 16px;
    line-height: 20px;
  }

  .googlePlay a img {
    width: 120px;
    margin-bottom: 10px;
  }

  .apple_store a img {
    width: 120px;
    margin-bottom: 10px;
  }

  .paly_store {
    display: flex;
    justify-content: center;
  }
}