.tetiomial {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #ecf0f1;
}

.testimonial_small p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: 10px;
}
.testimonial_head h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #2c3e50;
  margin-top: -10px;
  margin-bottom: -10px;
}

.review_card {
  background-color: #fff;
  padding: 35px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  height: 280px;
  border-radius: 5px;
  margin-top: 50px;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.review_card:hover {
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.review_author {
  display: flex;
  width: 100%;
}
.review_info {
  display: flex;
  flex-direction: column;
  margin-left: 35px;
}
.reviw_star {
  color: #e74c3c;
  margin-top: 10px;
}
.review_img {
  width: 80px;
  height: 80px;
}
.review_img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.rivew_name p {
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  color: #2c3e50;
}
.rivew_degination {
  margin-top: -20px;
}
.rivew_degination p {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #2c3e50;
}
.review_text {
  margin-top: 15px;
}
.review_text p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2c3e50;
  text-align: justify;
}
.review_card > .swiper-slide img {
  width: 280px;
}
.tetiomial .swiper {
  height: 380px !important;
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .tetiomial {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .tetiomial .swiper {
    height: 460px !important;
  }
  .testimonial_head h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .testimonial_small p {
    margin-bottom: -15px;
  }
  .review_card {
    height: 370px;
  }
}
